<template>
  <div class="space-y-6">
    <div
      class="mt-2 bg-red-600 p-4 rounded-lg text-white"
      v-if="actionRequired !== null && actionRequired.entityExpired"
    >
      One or more of your social connections require attention. Go to
      <a href="#">settings</a> to relink your connection.
    </div>
    <div class="flex flex-row space-x-6">
      <div
        class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 flex flex-col justify-between w-full lg:w-4/5"
      >
        <div class="flex flex-col md:flex-row justify-between items-center">
          <h2
            class="w-full md:w-auto flex flex-row font-bold items-center content-center justify-between text-lg"
          >
            <span>Your team</span>
            <a
              class="text-xs font-normal bg-gray-500 px-2 py-1 rounded ml-2 text-white"
              >Personal</a
            >
          </h2>
          <TextLoader
            :loading="loadingTeamData"
            width="64"
            class="w-full md:w-auto"
          >
            <span class="inline-block text-gray-600 text-sm pt-2 md:pt-0"
              >Current billing period (Nov 7 to Dec 7)</span
            >
          </TextLoader>
        </div>

        <div class="border-b my-2"></div>
        <p class="py-2">Welcome back. Here's a quick overview of your team.</p>
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div class="bg-gray-50 overflow-hidden shadow rounded-lg">
            <div class="w-full h-2 bg-gray-300"></div>
            <div class="p-3 flex flex-col">
              <dl>
                <dt class="text-sm">Bandwidth utilized</dt>
                <dd
                  class="text-black font-bold space-x-2 inline-block align-baseline"
                >
                  <TextLoader :loading="loadingTeamData">
                    <span class="inline-block align-baseline text-lg pr-1"
                      >0 KB
                    </span>
                    <span
                      class="text-gray-500 text-sm inline-block align-baseline"
                    >
                      / 100 GB</span
                    >
                  </TextLoader>
                </dd>
              </dl>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden shadow rounded-lg">
            <div class="w-full h-2 bg-gray-300"></div>
            <div class="p-3 flex flex-col">
              <dl>
                <dt class="text-sm">Transcoding minutes utilized</dt>
                <dd class="text-lg text-black font-bold">
                  <TextLoader :loading="loadingTeamData">
                    <span class="inline-block align-baseline text-lg">0</span>
                    <span class="text-gray-500 text-sm"> / &infin;</span>
                  </TextLoader>
                </dd>
              </dl>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden shadow rounded-lg">
            <div class="w-full h-2 bg-gray-300"></div>
            <div class="p-3 flex flex-col">
              <dl>
                <dt class="text-sm">Team members</dt>
                <dd class="text-lg text-black font-bold">
                  <TextLoader :loading="loadingTeamData">
                    <span> {{ users.length }}</span>
                  </TextLoader>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-white rounded-lg shadow px-3 py-5 sm:px-6 flex flex-col hidden lg:block lg:w-1/5"
      >
        <img v-if="profileImage" :src="profileImage.publicImageUrl" />
        <!-- <div
        class="h-44 w-full bg-gray-800 text-white flex items-center content-center justify-center text-5xl"
        >
          CS
        </div>
        <!-- <img
          src="https://scontent-lga3-1.xx.fbcdn.net/v/t1.0-9/67723099_2639790082699756_7832516422155632640_n.png?_nc_cat=101&ccb=2&_nc_sid=09cbfe&_nc_ohc=nQuHdN8vnIEAX-Jiupy&_nc_ht=scontent-lga3-1.xx&oh=a38af01a7d615d4d79963bc5e96425e2&oe=5FCD4BBA"
        /> -->
      </div>
    </div>
    <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 flex flex-col">
      <h2 class="font-bold flex flex-row items-center content-center text-lg">
        Recent content
      </h2>
      <div class="border-b my-2"></div>
      <p v-if="recentPosts.length == 0 || recentPosts == null">
        No posts created through Project Splitz.
        <a href="#" class="text-blue-700">Create your first post</a> to start
        populating content both here, and on your social media page(s).
      </p>
      <div class="space-y-2">
        <div
          class="bg-gray-50 p-4 rounded-md flex flex-row items-center content-center space-x-4"
          v-for="post in recentPosts"
          v-bind:key="post.id"
        >
          <i class="fad fa-film-alt"></i>
          <span>{{ post.title }}</span>
          <div class="flex flex-row flex-1 justify-end space-x-2">
            <span v-for="post in post.social_posts" v-bind:key="post.id">
              <i
                class="fa-brands fa-facebook"
                v-if="post.social_entity.type == 'facebook'"
              ></i>
              <i
                class="fa-brands fa-youtube"
                v-if="post.social_entity.type == 'youtube'"
              ></i>
            </span>
          </div>

          <!-- <span>{{ post.status }}</span> -->
          <span>{{ post.created_at | displayDateTime }}</span>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6 flex flex-col">
      <h2 class="font-bold flex flex-row items-center content-center text-lg">
        Recent events
      </h2>
      <div class="border-b my-2"></div>
      <p>
        You can review recent account activity here, such as but not limited to,
        users uploading video and transcoding progress.
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import TextLoader from "@/screens/App/General/Loaders/TextLoader";

export default {
  name: "AppLayout",
  components: { TextLoader },
  computed: {
    ...mapState("auth", {
      teams: (state) => state.teams,
    }),
    ...mapState("team", {
      actionRequired: (state) => state.actionRequired,
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
      loadingTeamData: (state) => state.loadingTeamData,
      recentPosts: (state) => state.recentPosts,
      profileImage: (state) => state.profileImage,
    }),
  },
};
</script>
